import {httpGet, httpPost} from '@/plugins/request'

/**
 * 获取七牛token
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetQiniuToken(query = [])
{
    return httpGet('/manage/common/qiniu/token', query)
}

/**
 * 分享链获取七牛token
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetShareQiniuToken(query = [])
{
    return httpGet('/share/qiniu/token', query)
}

/**
 * 获取城市
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetCities(query = []) {
    return httpGet('/manage/common/city', query)
}

/**
 * jssdk
 * @param data
 * @returns {Promise<*>}
 */
export function apiGetJssdk(data)
{
    return httpPost('/share/wework/jssdk', data);
}

/**
 * agent jssdk
 * @param data
 * @returns {Promise<*>}
 */
export function apiGetAgentJssdk(data)
{
    return httpPost('/share/wework/agent-jssdk', data);
}
