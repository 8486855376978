import {httpGet, httpPost} from '@/plugins/request'


/**
 * 获取收款人列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectPersons(query = [])
{
    return httpGet('/share/wework/project-person', query)
}

/**
 * 新增收款人
 *
 * @param data
 * @returns {Promise<*>}
 */
export function apiStoreProjectPerson(data)
{
    return httpPost('/share/wework/project-person', data)
}


/**
 * 获取合同列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectContracts(query = [])
{
    return httpGet('/share/wework/project-contract', query)
}

/**
 * 获取预算列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectBudget(query = [])
{
    return httpGet('/share/wework/project-budget', query)
}


/**
 * 获取备用金列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetImpress(query = [])
{
    return httpGet('/share/wework/impress', query)
}
