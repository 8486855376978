<template>
  <div style="padding: 15px;" v-show="this.allow">
    <div v-text="test"></div>

    <div>
      <el-row>
        <el-col :span="14">
          <el-input placeholder="搜索收款人" @change="getPersons" v-model="searchData.keyword">
            <template v-slot:append>
              <el-button>搜索</el-button>
            </template>
          </el-input>
        </el-col>
        <el-col :span="8" style="text-align:right;">
          <el-button type="primary" @click="clickCreate">添加</el-button>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-card :style="item.id === selectId ? 'border:1px solid red;margin-top:6px;' : 'margin-top:6px;'" v-for="(item, key) in options" :key="key" @click="selectData(item)">
        <el-row>
          <el-col :span="24">
            <div><span v-text="item.name"></span> <el-tag v-text="item.remark" type="info"></el-tag></div>
            <div><span v-text="item.type_desc"></span>：<span v-text="item.account"></span></div>
          </el-col>
        </el-row>
      </el-card>
    </div>

    <div v-show="options.length <= 0">
      <div style="text-align: center;">暂无数据</div>
    </div>


    <!-------- 弹出框表单 start ---------->
    <el-dialog v-model="formData.show" title="添加收款人" fullscreen append-to-body>
      <el-form
          ref="person"
          :rules="formData.rules"
          label-width="100px"
          :model="formData.data"
      >
        <el-form-item label="类型" prop="type">
          <el-select v-model="formData.data.type">
            <el-option label="银行卡" :value="1"></el-option>
            <el-option label="支付宝" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formData.data.name" placeholder="收款人"/>
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input v-model="formData.data.account" placeholder="账号"/>
        </el-form-item>
        <el-form-item label="开户行" prop="bank" v-show="formData.data.type === 1">
          <el-input v-model="formData.data.bank" placeholder="输入开户行" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="formData.data.remark" placeholder="备注信息，必填"/>
        </el-form-item>
        <el-form-item label="身份证号" prop="idcard">
          <el-input v-model="formData.data.idcard" placeholder="身份证号码，18位"/>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="formData.data.mobile" placeholder="手机号，11位"/>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="closeDialog">取消</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <!-------- 弹出框表单 end ---------->
  </div>
</template>

<script>
import {apiGetAgentJssdk, apiGetJssdk} from '@/api/commonApi'
import {apiGetProjectPersons, apiStoreProjectPerson} from '@/api/shareWeworkProjectApi'
import * as ww from "@wecom/jssdk"

let formData = {
  id: 0,
  name: '',
  type: '',
  account: '',
  bank: '',
  idcard: '',
  mobile: '',
  remark: '',
}

export default {
  created() {
    if (this.$route.query.token) {
      this.allow = true
      this.key = this.$route.query.key
      this.init()
      this.getPersons()
    }
  },
  data() {
    return {
      test: '',
      key: '',
      selectId: 0,
      allow: false,
      options: [],
      searchData: {
        company_id: 0,
        per_page: 500,
        page: 1,
        keyword: ''
      },
      jsapi: ['saveApprovalSelectedItems', 'getApprovalSelectedItems'],
      // 创建表单
      formData:{
        lock: false,
        data: formData,
        rules: {
          name: [{
            required: true,
            message: '请输入收款人姓名',
            trigger: 'blur',
          }],
          account: [{
            required: true,
            message: '请输入收款的账号',
            trigger: 'blur',
          }],
          type: [{
            required: true,
            message: '请选择类型',
            trigger: 'blur',
          }],
          remark: [{
            required: true,
            message: '备注信息',
            trigger: 'blur',
          }],
        },
        show: false,
      },
    }
  },
  methods: {
    getPersons() {
      this.searchData.company_id = this.$route.query.company_id,
      apiGetProjectPersons(this.searchData).then(rsp => {
        this.options = rsp.data
      })
    },
    clickCreate() {
      this.formData.show = true
    },
    formLock() {
      this.formData.lock = true
    },
    formUnlock() {
      this.formData.lock = false
    },
    closeDialog() {
      this.formData.show = false
    },
    onSubmit() {
      if (this.formData.lock) {return;}
      this.formLock()
      this.$refs['person'].validate(valid => {
        if (valid) {
          this.formData.data.company_id = this.$route.query.company_id,

          apiStoreProjectPerson(this.formData.data).then(() => {
            this.$message.success('创建成功')
            this.getPersons()
            this.closeDialog()
            this.formUnlock()
          }).catch(() => {
            this.formUnlock()
          })
        }
      })
    },
    init() {
      this.ua = navigator.userAgent.toLowerCase()
      if (/wxwork/i.test(this.ua)) {
        ww.register({
          corpId: 'ww07f39146d675193a',
          agentId: '1000003',
          jsApiList: this.jsapi,
          getConfigSignature: async (url) => {
            return await apiGetJssdk({
              url: url,
              apiList: this.jsapi,
            })
          },
          getAgentConfigSignature: async (url) => {
            return await apiGetAgentJssdk({
              url: url,
              apiList: this.jsapi,
            })
          },
          onConfigFail: (data) => {
            this.test = data
            // this.text = '还未支持此登陆方式(01)'
          },
          onAgentConfigFail: (data) => {
            this.test = data
            // this.text = '还未支持此登陆方式(01)'
          },
        })
      }
    },
    selectData (value) {
      ww.saveApprovalSelectedItems({
        key: this.key,
        selectedData: [{
          key: value.id.toString(),
          value: value.name + ' 「' + value.remark + '」 ' + value.account + '（'+ value.type_desc  +'）' + ' ' + value.bank,
        }],
        success: () => {
          this.selectId = value.id
        },
        fail: (res) => {
          this.test = res
        }
      })
    }
  }

}
</script>
